import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Text,
  getSpace,
  Button,
  getBorder,
  SpinnerIcon,
  Error,
  Label,
} from "@fatlama/llama-library";
import GetApi from "../../utils/api";
import styled from "styled-components";
import { Field, Formik } from "formik";
import formikFieldWrapper from "../Common/formikFieldWrapper";
// import { useSelector } from "../../state/utils";
// import { getUser } from "../../state/selectors";
import TextArea from "../Common/TextArea";
import dayjs from "dayjs";

const FormInput = formikFieldWrapper(TextArea);

const NotesContainer = styled(Flex)`
  height: 270px;
  overflow-y: scroll;
  white-space: pre-wrap;
  flex-direction: column;
  border: ${getBorder("thinLight")};
  border-radius: 16px;
  background-color: white;
  font-size: 12px;
  padding-left: ${getSpace(3)};
  padding-right: ${getSpace(3)};
  padding-bottom: ${getSpace(2)};
  padding-top: ${getSpace(2)};
  p {
    font-size: 12px;
    line-height: 20px;
  }
`;

const CommunicationsAndNotes = ({
  userId,
  title,
  noteContextId,
}: {
  userId: number;
  title?: string;
  noteContextId: string;
}): any => {
  const [notes, setUserNotes] = useState([]);
  // const user = useSelector(getUser);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const api = GetApi();

  const getUserNotes = async () => {
    const res = await getNotes(userId);
    setUserNotes(res);
  };

  const getNotes = async (userId: number) => {
    try {
      setError(undefined);
      setIsLoading(true);

      const res = await api.getUserNotes(userId);
      console.log(res);

      if (!res.success) {
        setError("Cannot get notes");
        return;
      }
      const data = res.payload;
      return data;
    } catch (error) {
      setError("Cannot get notes");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const updateNotes = async (note: string) => {
    const payload = {
      userId,
      text: note,
    };

    await api.addUserNote(payload);

    getUserNotes();
  };

  const onSubmit = async (values: any, formik: any) => {
    try {
      await updateNotes(values.note);
      formik.setFieldValue("note", "");

      formik.setSubmitting(false);
    } catch (err) {
      console.log(err, "-err");
    }
  };

  if (error) {
    return <Error>{error}</Error>;
  }

  if (isLoading && !notes) {
    return (
      <Flex height="400px" alignItems="center" justifyContent="center">
        <SpinnerIcon />
      </Flex>
    );
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        note: "",
      }}
      validate={(values) => (!values.note ? { note: "Required" } : {})}
      render={(formikBag) => {
        const {
          setFieldTouched,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          isValid,
        } = formikBag;
        return (
          <Flex flexDirection="column">
            <NotesContainer mb={3}>
              {notes.map((note: any) => {
                return (
                  <Box mb={2}>
                    <Text bold mb={0}>
                      Admin: {note.adminName}{" "}
                      {dayjs(note.createdAt).format("D MMM YY, HH:mm")}
                    </Text>

                    <Text>{note.text}</Text>
                  </Box>
                );
              })}
            </NotesContainer>
            <Box>
              <Label>Add note to {title || "user"} </Label>

              <Field
                mb={3}
                name="note"
                type="text"
                style={{ height: "40px" }}
                maxRows={5}
                minRows={3}
                required={true}
                onChange={(e: any) => {
                  setFieldTouched("note", true, false);
                  setFieldValue("note", e.target.value);
                }}
                label={`Add note for ${title || "user"}`}
                component={FormInput}
              />
            </Box>

            <Button
              // variant='secondary'
              onClick={handleSubmit}
              large
              fullWidth
              label={`Add ${title || "user"} note`}
              isLoading={isSubmitting}
              disabled={!isValid}
            />
          </Flex>
        );
      }}
    />
  );
};

export default CommunicationsAndNotes;
