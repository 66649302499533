import React from "react";
import styled from "styled-components";
import {
  Flex,
  getColor,
  Button,
  H3,
  Input,
  Text,
} from "@fatlama/llama-library";
import { color, border } from "styled-system";
import GetApi from "../../utils/api";
import { Field, Formik } from "formik";
import formikFieldWrapper from "../Common/formikFieldWrapper";
// import { useHistory } from "react-router-dom";
import { getBaseurl } from "../../helpers/environment-helper";

const FormInput = formikFieldWrapper(Input);

const validate = (values: any) => {
  const errors = {} as any;
  if (!values.username) {
    errors.username = "Required";
  }
  if (!values.password) {
    errors.password = "Required";
  }

  return errors;
};

export const AuthButton = styled.a`
  border: 1px solid ${getColor("gsRat")};
  ${border};
  ${color};
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 40px;
  z-index: 5;
  cursor: pointer;
  padding: 0px;
` as any;

const FullScreenCentered = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const Login = () => {
  const api = GetApi();
  // const history = useHistory();

  const onSubmit = async (values: any, formik: any) => {
    try {
      const res = await api.login(values);
      console.log(res, "-res");
      if (res.adminId) {
        // history.push(`${history.location.pathname}?refresh=true`);
        window.location.reload();
      }
      // if (res.success) {
      //   history.push(`${history.location.pathname}?refresh=true`);
      // }
    } catch (error) {
      console.log(error, "-------error");
    }
  };
  const currentBaseUrl = getBaseurl();

  return (
    <FullScreenCentered>
      <Flex
        alignItems="center"
        maxWidth="350px"
        flexDirection="column"
        pt="56px"
        pb="150px"
      >
        <Text small>
          {!currentBaseUrl ||
          currentBaseUrl === "https://api.hygglo.se/api/admin"
            ? "Production"
            : "Staging"}
        </Text>

        <Formik
          onSubmit={onSubmit}
          initialValues={{
            username: "",
            password: "",
          }}
          validate={validate}
          render={(formikBag) => {
            const {
              // setFieldTouched,
              isSubmitting,
              handleSubmit,
              // setFieldValue,
              isValid,
              // values,
            } = formikBag;
            return (
              <Flex px={3} py={2} flexDirection="column">
                <H3 mb={2}>Login</H3>

                <Field
                  mb={3}
                  name="username"
                  type="text"
                  required={true}
                  label="Username"
                  component={FormInput}
                />

                {/* <Label>Add note to borrower</Label> */}
                <Field
                  mb={3}
                  name="password"
                  type="password"
                  required={true}
                  label="Password"
                  component={FormInput}
                />
                <Button
                  // variant='secondary'
                  onClick={handleSubmit}
                  large
                  fullWidth
                  label="Login"
                  isLoading={isSubmitting}
                  disabled={!isValid}
                />
              </Flex>
            );
          }}
        />

        {/* <AuthButton bg="white" href={`${baseURL}/api/v1/admin/google-login`}>
          <IconContainer>
            <Logo src={`${BASE_ASSETS_URL}/microsite/logos/google.png`} />
          </IconContainer>
          <BreakGoogle />
          <Text mb={0}>Continue with Google</Text>
        </AuthButton> */}
      </Flex>
    </FullScreenCentered>
  );
};

export default Login;
