import React from "react";
import { Flex, SpinnerIcon, Error, Text } from "@fatlama/llama-library";
import { DoubleDataRow } from "./Components";
import dayjs from "dayjs";
import formatWithCurrency from "../../helpers/formatWithCurrency";
// import { Link } from "react-router-dom";
// import { Page } from "../../interfaces";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const UserDetails = ({
  lender,
  isLoadingLender,
  isLoadingBorrower,
  error,
  borrower,
  highestValueCOMPLETEDRental,
}: any) => {
  if (isLoadingLender || isLoadingBorrower || !borrower || !lender) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <SpinnerIcon />
      </Flex>
    );
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  return (
    <Flex flexDirection="column" id="customerDetails">
      <Flex mb={2}>
        <Text small mb={0} width={1 / 3}></Text>
        <Text small color="copyTwo" bold mb={0} width={1 / 3}>
          Renter
        </Text>
        <Text small color="copyTwo" bold mb={0} width={1 / 3}>
          Owner
        </Text>
      </Flex>
      <DoubleDataRow
        title="Name"
        value={`${borrower.firstName} ${borrower.lastName}`}
        value2={`${lender.firstName} ${lender.lastName}`}
      />
      <DoubleDataRow
        bg="gsMist"
        title="User ID"
        value={`${borrower.id}`}
        value2={`${lender.id}`}
      />
      <DoubleDataRow
        title="Email"
        value={`${borrower.email}`}
        value2={`${lender.email}`}
      />
      <DoubleDataRow
        bg="gsMist"
        title="Telephone"
        value={`${borrower.phone}`}
        value2={`${lender.phone}`}
      />
      <DoubleDataRow
        title="Signup Date"
        value={dayjs(borrower.createdAt).utc().format("D MMM YYYY")}
        value2={dayjs(lender.createdAt).utc().format("D MMM YYYY")}
      />
      <DoubleDataRow
        bg="gsMist"
        title="Signed up with"
        value={`${borrower.signedUpWith}`}
        value2={`${lender.signedUpWith}`}
      />
      <DoubleDataRow
        title="Highest value COMPLETED rental"
        value={
          highestValueCOMPLETEDRental > 0
            ? `${formatWithCurrency(highestValueCOMPLETEDRental, "GBP")}`
            : "N/A"
        }
        value2={`-`}
      />

      <DoubleDataRow
        title="Response time"
        value={`${borrower.responseTime || "N/A"}`}
        value2={`${lender.responseTime || "N/A"}`}
      />
      {/* 
      <DoubleDataRow
        bg="gsMist"
        title="Conversations"
        value={
          <Link to={`${Page.conversations}/${borrower.id}`}>
            <Text small bold color="whale" mb={0}>
              View
            </Text>
          </Link>
        }
        value2={
          <Link to={`${Page.conversations}/${lender.id}`}>
            <Text small bold color="whale" mb={0}>
              View
            </Text>
          </Link>
        }
      /> */}
      <DoubleDataRow
        title="Stripe customer account"
        value={
          <a
            href={`https://dashboard.stripe.com/customers/${borrower.stripeCustomerId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text small bold color="whale" mb={0}>
              View
            </Text>
          </a>
        }
        value2={"-"}
      />
      <DoubleDataRow
        title="Stripe connect account"
        value={"-"}
        value2={
          <a
            href={`https://dashboard.stripe.com/connect/accounts/${lender.stripeAccountId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text small bold color="whale" mb={0}>
              View
            </Text>
          </a>
        }
      />
    </Flex>
  );
};

export default UserDetails;
