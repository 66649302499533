import React, { useState } from "react";
import { Formik } from "formik";
import {
  Flex,
  Box,
  Button,
  Text,
  Modal,
  H3,
  Error,
} from "@fatlama/llama-library";
import GetApi from "../../utils/api";
import { useHistory } from "react-router-dom";
import { OrderDataType } from "../../interfaces";
import Dropdown from "../Common/Dropdown";
import { BORROWER_REASONS } from "../../constants/cancellationReasons";

interface Props {
  order: OrderDataType;
  redButton?: boolean;
}

interface FormValues {
  reason: string;
}

const validate = ({}: {}) => (values: FormValues) => {
  const errors = {} as any;
  const { reason } = values;

  if (!reason) {
    errors.reason = "Required";
  }

  return errors;
};

const RejectRental = ({ order, redButton }: Props) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [error, setError] = useState<string>("");
  const history = useHistory();
  const { orderId, orderStatus, paymentStatus, securityStatus } = order;
  const api = GetApi();
  const onSubmit = async (
    values: FormValues,
    { setSubmitting, isSubmitting }: any
  ) => {
    if (isSubmitting) {
      return;
    }
    setSubmitting(true);
    const { reason } = values;
    try {
      setError("");

      const payload = {
        reason,
        orderId,
      };
      const res = await api.rejectRental(payload);

      if (res.success) {
        history.push(`${history.location.pathname}?refresh=true`);
        setShowCancelModal(false);
      } else {
        setError("Unable to cancel rental");
      }
    } catch (error) {
      console.log(error);
      setError("Unable to cancel rental");
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = {
    reason: "",
  };

  if (["DENIED", "CANCELED"].includes(orderStatus)) {
    return null;
  }

  const validationFunction = validate({ orderStatus, paymentStatus });

  if (
    securityStatus !== "IN_MANUAL_REVIEW" &&
    securityStatus !== "UNFULFILLED_REQS"
  ) {
    return (
      <Text small>
        You can only reject orders in security statuses: IN_MANUAL_REVIEW and
        UNFULFILLED_REQS. Please use hygglo admin to cancel this order.
      </Text>
    );
  }
  return (
    <>
      <Button
        mr={2}
        fullWidth={redButton}
        variant={redButton ? "alert" : "secondary"}
        onClick={() => setShowCancelModal(true)}
        label="Reject rental"
      />

      <Modal isOpen={showCancelModal} onClose={() => setShowCancelModal(false)}>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validationFunction}
        >
          {({
            handleSubmit,
            values,
            setFieldValue,
            errors,
            touched,
            isSubmitting,
            isValid,
            setFieldTouched,
          }) => {
            return (
              <Flex px={3} py={2} flexDirection="column">
                <H3 mb={2}>Cancel transaction</H3>
                <Box>
                  <Box mb={3}>
                    <Dropdown
                      label="Cancellation reason"
                      error={
                        errors.reason && touched.reason ? errors.reason : undefined
                      }
                      options={BORROWER_REASONS}
                      handleChange={(event: any) => {
                        setFieldValue("reason", event.target.value);
                        setFieldTouched("reason", true, false);
                      }}
                      value={values.reason}
                    />
                  </Box>

                  <Text small>(Automatic refund for borrower)</Text>

                  {error && <Error>{error}</Error>}
                </Box>
                <Button
                  alert
                  variant="primary"
                  isLoading={isSubmitting}
                  disabled={!isValid || isSubmitting}
                  onClick={handleSubmit}
                  label="Reject"
                />
              </Flex>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default RejectRental;
