import React, { useState, useEffect } from "react";
import { Flex, Box, SpinnerIcon } from "@fatlama/llama-library";
import Header from "./Header";
import {
  Tab,
  VerificationStatusFilter,
  QueryResponse,
  SortableField,
  SortOrder,
  CountryFilter,
} from "./types";
import Results from "./Results";
import GetApi from "../../utils/api";
import ResultFilters from "./ResultFilters";
import { getFiltersFromTabsAndFilter } from "./utils";
import Pagination from "../Common/Pagination";

import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import * as QueryString from "query-string";
import debounce from "debounce-promise";

// const usePrevious = <T extends unknown>(value: T): T | undefined => {
//   const ref = useRef<T>();
//   useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// };

interface GetRentalsProps {
  activeTab: Tab;
  pageNumber: number;
  searchTerm: string;
  setResults(x: any): any;
  activeFilter: VerificationStatusFilter;
  api: any;
  sortOrder: SortOrder;
  sortBy: SortableField;
  setIsLoading(x: boolean): any;
  abortController: any;
  dateFilter: any;
  setDateFilter: any;
  countryFilter: CountryFilter;
  customerId?: number;
}

const getRentals = async ({
  setResults,
  activeTab,
  activeFilter,
  searchTerm,
  pageNumber,
  api,
  sortBy,
  sortOrder,
  setIsLoading,
  abortController,
  dateFilter,
  setDateFilter,
  countryFilter,
  customerId,
}: GetRentalsProps) => {
  setIsLoading(true);

  const filters = {
    ...getFiltersFromTabsAndFilter({ activeTab, activeFilter }),
    countryFilter,
    searchTerm: (searchTerm || "").trim(),
  };
  if (customerId) {
    filters["customerId"] = customerId;
  }
  if (dateFilter && dateFilter[0] && dateFilter[1]) {
    filters["fromDate"] = dateFilter[0];
    filters["toDate"] = dateFilter[1];
  }

  const signal = abortController.signal;

  const res = await api.getRentals(
    { pageNumber, filters, sortBy: { sortByField: sortBy, sortOrder } },
    { signal }
  );

  // IMPORTANT: we still need to filter the results here,
  // in case abortion happens during the delay.
  // In real apps, abortion could happen when you are parsing the json,
  // with code like "fetch().then(res => res.json())"
  // but also any other async then() you execute after the fetch
  if (abortController.signal.aborted) {
    return;
  }

  if (res.success) {
    const results: QueryResponse = res.payload;

    setResults(results);
  }
  setIsLoading(false);
};

const debouncedGetRentals = debounce(getRentals, 500);

const primaryTabFilterMap = {
  [Tab.ACTIVE]: VerificationStatusFilter.ALL,
  [Tab.PAST]: undefined,
} as any;

const initialResults: QueryResponse & {
  noInitialLoad?: boolean;
} = {
  pageNumber: 1,
  pageCount: 1,
  total: 0,
  txs: [],
  noInitialLoad: true,
};

const Rentals = ({ customerId }: { customerId?: number }) => {
  const history = useHistory();
  let { search } = useLocation();
  const { path } = useRouteMatch();

  const params = QueryString.parse(search);

  const [sortBy, setSortBy] = useState<SortableField>(
    params.sortBy || SortableField.TX_FROM_DATE
  );
  const [countryFilter, setCountryFilter] = useState<CountryFilter>(
    params.countryFilter || CountryFilter.ALL
  );
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    params.sortOrder || SortOrder.ASCENDING
  );

  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState(initialResults);
  const [searchTerm, setSearchTerm] = useState(params.searchTerm || "");
  const [dateFilter, setDateFilter] = useState<string[]>();
  const [activeTab, setActiveTab] = useState(
    params.activeTab || (customerId ? Tab.ALL : Tab.ACTIVE)
  );
  const initialFilter = customerId
    ? VerificationStatusFilter.ALL
    : params.activeFilter ||
      (primaryTabFilterMap[Tab.ACTIVE] as VerificationStatusFilter | undefined);
  const [activeFilter, setActiveFilter] = useState(initialFilter);
  const [headerHeight, setheaderHeight] = useState(90);

  const api = GetApi();

  const getAndSetHeight = () => {
    const header = document.getElementById("header");
    if (header) {
      setheaderHeight(header.offsetHeight);
    }
  };

  useEffect(() => {
    getAndSetHeight();
    window.addEventListener("resize", getAndSetHeight);
    return () => {
      window.removeEventListener("resize", getAndSetHeight);
    };
  }, []);

  const clearFilters = () => {
    setActiveFilter(primaryTabFilterMap[Tab.ACTIVE]);
    setSearchTerm("");
    setActiveTab(Tab.ACTIVE);
    setDateFilter(undefined);
  };

  useEffect(() => {
    const updatedParams = {
      searchTerm,
      activeFilter,
      activeTab,
      sortBy,
      sortOrder,
      countryFilter,
    };
    const params = QueryString.stringify(updatedParams);
    history.push(path + params ? `?${params}` : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, activeFilter, activeTab, sortBy, sortOrder, countryFilter]);

  const setPageNumber = (pageNumber: number) => {
    setResults({
      ...results,
      pageNumber,
    });
  };
  const abortController = new AbortController();

  useEffect(() => {
    getRentals({
      setResults,
      activeTab,
      activeFilter,
      searchTerm,
      pageNumber: results.pageNumber,
      api,
      sortBy,
      sortOrder,
      setIsLoading,
      abortController,
      dateFilter,
      setDateFilter,
      countryFilter,
      customerId,
    });

    // Trigger the abortion in useEffect's cleanup function
    return () => {
      //abortController.abort();
      console.log("aborting?");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTab,
    activeFilter,
    results.pageNumber,
    sortBy,
    sortOrder,
    dateFilter,
    countryFilter,
  ]);

  useEffect(() => {
    debouncedGetRentals({
      setResults,
      activeTab,
      activeFilter,
      searchTerm,
      pageNumber: results.pageNumber,
      api,
      setIsLoading,
      sortBy,
      sortOrder,
      abortController,
      dateFilter,
      setDateFilter,
      countryFilter,
      customerId,
    });

    // Trigger the abortion in useEffect's cleanup function
    return () => {
      //abortController.abort();
      console.log("aborting?");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    setPageNumber(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, activeFilter]);

  return (
    <Flex flexDirection="column">
      <Header
        id="header"
        searchTerm={searchTerm}
        activeTab={activeTab}
        activeFilter={activeFilter}
        setActiveTab={setActiveTab}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        countryFilter={countryFilter}
        setCountryFilter={setCountryFilter}
        sortBy={sortBy}
        setSortBy={setSortBy}
        setSearchTerm={setSearchTerm}
        isLoading={isLoading}
        clearFilters={clearFilters}
        setDateFilter={setDateFilter}
        dateFilter={dateFilter}
        customerId={customerId}
      />
      <Flex position="relative" pt={headerHeight + 16}>
        <Box px={3} position="relative">
          <Flex mb={3}>
            <ResultFilters
              activeTab={activeTab}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
          </Flex>
          {results.noInitialLoad ? (
            <Flex alignItems="center" justifyContent="center" minHeight="400px">
              <SpinnerIcon />
            </Flex>
          ) : (
            <Results
              isLoading={isLoading}
              searchTerm={searchTerm}
              results={results.txs}
              inAll={activeFilter === VerificationStatusFilter.ALL}
              isSecurityReviewView={
                activeFilter === VerificationStatusFilter.SECURITY_REVIEW
              }
            />
          )}
          <Pagination
            disabled={isLoading}
            currentPage={results.pageNumber}
            totalPages={results.pageCount}
            total={results.total}
            goToPage={setPageNumber}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Rentals;
