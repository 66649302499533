import React from "react";
import { Flex, SpinnerIcon, Box, Text, getColor } from "@fatlama/llama-library";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { color } from "styled-system";
import { TH, TD } from "../Common/Tables";
import { Duplicates, RentalAbilityPause } from "../../interfaces/index";
import dayjs from "dayjs";
import { getAdminUrl } from "../../helpers/environment-helper";

const TRDodgy = styled.tr`
  background-color: ${getColor("pomNeon")} !important;
  color: white !important;
  p {
    color: white !important;
  }
`;

const TRNormal = styled.tr``;

const Dot = styled(Box)`
  width: 16px;
  height: 16px;
  ${color};
  border-radius: 50%;
`;
const Text2Line = styled(Text)`
  word-break: break-all;
`;

const DuplicatesList = ({
  userId,
  rentalAbilityPause,

  duplicates,
  isLoading,
}: {
  userId: number;

  rentalAbilityPause: RentalAbilityPause | null;
  duplicates?: Duplicates;
  isLoading: boolean;
}): any => {
  const history = useHistory();

  if (!duplicates && isLoading) {
    return (
      <Flex height="400px" alignItems="center" justifyContent="center">
        <SpinnerIcon />
      </Flex>
    );
  }
  if (!duplicates) {
    return <Text>No duplicates</Text>;
  }

  const { accounts } = duplicates;

  const rentalPausedDuplicateMatch =
    accounts &&
    rentalAbilityPause &&
    rentalAbilityPause.duplicateUserId &&
    accounts[rentalAbilityPause.duplicateUserId];

  return (
    <Flex flexDirection="column" id="duplicates">
      {rentalPausedDuplicateMatch ? (
        <Flex flexDirection="column">
          <Text small mb={2} bold alert>
            Warning, this user has been matched to another account which has a
            rental restriction on it!
          </Text>

          <Text small mb={2} bold>
            The most severe block is user {rentalAbilityPause.duplicateUserId}{" "}
            which has block severity of: (
            {
              accounts[rentalAbilityPause.duplicateUserId].rentalAbilityPause
                ?.pausedBecause
            }
            )
          </Text>

          <Text small mb={2} bold>
            SCROLL TO END TO CONFIRM/UPDATE this restriction
          </Text>

          {/* <RentalAbilityPauseComponent userId={userId} /> */}
        </Flex>
      ) : null}
      <table>
        <tbody>
          <tr>
            <TH></TH>
            <TH></TH>
            <TH></TH>
            {/* <TH></TH>
            <TH></TH> */}
            {/* <TH></TH> */}
            <TH></TH>

            <TH></TH>
            <TH></TH>
            <TH>
              <Text small mb={0}>
                Matched values
              </Text>
            </TH>
            <TH></TH>

            <TH></TH>
            <TH></TH>
            <TH></TH>
          </tr>
          <tr>
            <TH>ID</TH>
            <TH>Email</TH>
            <TH>Telephone</TH>
            <TH>dateOfBirth</TH>
            {/* <TH>Name</TH>
            <TH>Postcode</TH> */}

            <TH>Tel.</TH>
            <TH>Card fingerprint</TH>

            <TH>Email</TH>
            <TH>Postcode</TH>
            <TH>Names</TH>
            <TH>Dob</TH>
            <TH>Rental ability pause</TH>
            <TH>Started orders (Clickable row)</TH>
            <TH>Paid orders</TH>
            <TH>No. of docs</TH>
            <TH>Deleted?</TH>

            {/* <TH>Self Referrer</TH> */}
          </tr>
          {accounts &&
            Object.keys(accounts).map((userId: any, i: number) => {
              const account = accounts[userId];

              const adminUrl = getAdminUrl(`/User/${userId}`);
              const onRowClick = () => {
                // history.push(`/customers/${userId}`);
                window.open(
                  adminUrl?.toString(),
                  "_blank" // <- This is what makes it open in a new window.
                );
              };
              const {
                id,
                email,
                telephone,
                birthDate,
                rentalAbilityPause,
                matchTypes,
                stats,
                documents,
                deletedAt,
                // stripeAddressPostalCode,
                // isFlaggedDodgy,
                // stripeFirstName,
                // stripeLastName,
                // tags,
                // isSelfReferrer
              } = account;
              // TODO: fix these and fitler in duplicate dodgy
              // const isFlaggedDodgy = tags && tags.isFlaggedDodgy;
              // const hasFlaggedDetails = tags && tags.hasFlaggedDetails;

              // const Tr: any = Boolean(isFlaggedDodgy || hasFlaggedDetails)
              //   ? TRDodgy
              //   : TRNormal;

              const Tr = rentalAbilityPause ? TRDodgy : TRNormal;
              return (
                <Tr
                  // bg={isFlaggedDodgy ? 'pomMute' : i % 2 === 0 ? 'white' : 'rgb(250, 250, 250)'}
                  onClick={onRowClick}
                >
                  <TD>
                    <Text small>{id}</Text>
                  </TD>
                  <TD>
                    <Text2Line small>{email}</Text2Line>
                  </TD>
                  <TD>
                    <Text small>{telephone}</Text>
                  </TD>
                  <TD>
                    <Text small>
                      {birthDate && dayjs(birthDate).format("D MMM YY")}
                    </Text>
                  </TD>
                  {/* <TD>
                    <Text small>{`${null || ""} ${null || ""}`}</Text>
                  </TD>
                  <TD>
                    <Text small>{null}</Text>
                  </TD> */}

                  <TD>
                    <Text
                      small
                      textAlign="center"
                      display="flex"
                      justifyContnet="center"
                    >
                      {matchTypes && matchTypes.includes("TELEPHONE") && (
                        <Dot bg={!rentalAbilityPause ? "pomNeon" : "white"} />
                      )}
                    </Text>
                  </TD>
                  <TD>
                    <Text
                      small
                      textAlign="center"
                      display="flex"
                      justifyContnet="center"
                    >
                      {matchTypes && matchTypes.includes("FINGERPRINT") && (
                        <Dot bg={!rentalAbilityPause ? "pomNeon" : "white"} />
                      )}
                    </Text>
                  </TD>

                  <TD>
                    <Text
                      small
                      textAlign="center"
                      display="flex"
                      justifyContnet="center"
                    >
                      {matchTypes && matchTypes.includes("EMAIL") && (
                        <Dot bg={!rentalAbilityPause ? "pomNeon" : "white"} />
                      )}
                    </Text>
                  </TD>
                  <TD>
                    <Text
                      small
                      textAlign="center"
                      display="flex"
                      justifyContnet="center"
                    >
                      {matchTypes && matchTypes.includes("POSTCODE") && (
                        <Dot bg={!rentalAbilityPause ? "pomNeon" : "white"} />
                      )}
                    </Text>
                  </TD>
                  <TD>
                    <Text
                      small
                      textAlign="center"
                      display="flex"
                      justifyContnet="center"
                    >
                      {matchTypes && matchTypes.includes("FULLNAME") && (
                        <Dot bg={!rentalAbilityPause ? "pomNeon" : "white"} />
                      )}
                    </Text>
                  </TD>
                  <TD>
                    <Text
                      small
                      textAlign="center"
                      display="flex"
                      justifyContnet="center"
                    >
                      {matchTypes && matchTypes.includes("DOB") && (
                        <Dot bg={!rentalAbilityPause ? "pomNeon" : "white"} />
                      )}
                    </Text>
                  </TD>
                  {/* <TD>
                    <Text small textAlign='center' display='flex' justifyContnet='center'>{isFlaggedDodgy && <Dot bg="white" />}</Text>
                  </TD> */}
                  <TD>
                    <Text
                      small
                      textAlign="center"
                      display="flex"
                      bold
                      justifyContnet="center"
                    >
                      {rentalAbilityPause
                        ? rentalAbilityPause.pausedBecause
                        : ""}
                    </Text>
                  </TD>

                  <TD
                    onClick={(e: any) => {
                      history.push(`/customer-rentals/${id}`);
                      e.stopPropagation();
                    }}
                  >
                    <Text
                      small
                      textAlign="center"
                      display="flex"
                      bold
                      justifyContnet="center"
                    >
                      {stats ? stats.customerPaidOrders : ""}
                    </Text>
                  </TD>
                  <TD>
                    <Text
                      small
                      textAlign="center"
                      display="flex"
                      bold
                      justifyContnet="center"
                    >
                      {stats ? stats.customerStartedOrders : ""}
                    </Text>
                  </TD>
                  <TD>
                    <Text
                      small
                      textAlign="center"
                      display="flex"
                      bold
                      justifyContnet="center"
                    >
                      {documents ? documents.length : ""}
                    </Text>
                  </TD>
                  <TD>
                    <Text
                      small
                      textAlign="center"
                      display="flex"
                      bold
                      justifyContnet="center"
                    >
                      {deletedAt ? dayjs(deletedAt).format("D MMM YY") : ""}
                    </Text>
                  </TD>
                  {/* <TD>{isSelfReferrer && <Dot bg={!isFlaggedDodgy ? 'pomNeon' : 'white'} />}</Text></TD> */}
                </Tr>
              );
            })}
        </tbody>
      </table>
    </Flex>
  );
};

export default DuplicatesList;
