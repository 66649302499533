import React, { useState } from "react";
import styled from "styled-components";
import {
  Flex,
  Box,
  Text,
  Modal,
  H3,
  Error,
  Button,
  getSpace,
} from "@fatlama/llama-library";
import RejectRental from "./RejectRental";
import {
  // OnlinePresenceLinkRequirementBlock,
  // DocumentRequirementBlock,
  OrderDataType,
} from "../../interfaces";
import GetApi from "../../utils/api";
import { useHistory } from "react-router-dom";
import CheckboxComponent from "../Common/CheckBox";
// import { useSelector } from "../../state/utils";
// import { getUser } from "../../state/selectors";
interface Props {
  // underReviewDocs?: OnlinePresenceLinkRequirementBlock[] &
  //   DocumentRequirementBlock[];
  order: OrderDataType;
}

const Row = styled(Flex)`
  pointer: cursor;
  align-items: center;
  margin-bottom: ${getSpace(3)};
`;

const getCheckList = () => {
  return [
    // `This rental has the required documents for the combined outstanding rentals of ${totalRentalValue}`,
    "I’ve reviewed the security rules and am happy to approve this rental.",
    "I do not need to escalate this for a manager review.",
  ];
};

const ChecklistSection = () => {
  const checkList = getCheckList();
  const initialCheckedIndicies: Record<number, boolean> = {
    0: false,
    1: false,
    2: false,
  };
  const [checkedIndices, setCheckedIndicies] = useState(initialCheckedIndicies);

  return (
    <Flex flexDirection="column" width="auto">
      {checkList.map((point: string, i: number) => {
        return (
          <Row
            onClick={() =>
              setCheckedIndicies({
                ...checkedIndices,
                [i]: !checkedIndices[i],
              })
            }
          >
            <CheckboxComponent checked={checkedIndices[i]} />
            <Text ml={2} mb={0}>
              {point}
            </Text>
          </Row>
        );
      })}
    </Flex>
  );
};

const ApprovalSection = ({ order }: Props) => {
  const canApproveTransaction =
    order.orderStatus === "ACCEPTED" &&
    (order.securityStatus === "IN_MANUAL_REVIEW" ||
      order.securityStatus === "UNFULFILLED_REQS") &&
    order.paymentStatus === "FUNDS_RESERVED";

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const history = useHistory();

  const api = GetApi();

  const approve = async () => {
    setOpenConfirmModal(false);
    setIsLoading(true);
    setError(undefined);

    try {
      let res;

      res = await api.approveTransaction(order.orderId);

      if (res.success) {
        history.push(`${history.location.pathname}?refresh=true`);
      } else {
        setError(res?.message || "Unable to approve rental");
      }
    } catch (error) {
      console.log(error, "-err hell YOOo");
      // setError("Unable to approve rental");
      const message = (error as any)?.message || "Unable to approve rental";
      setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const openConfirmModalPress = () => {
    setOpenConfirmModal(true);
  };

  return (
    <Box>
      <H3 mb={3}>Approvals</H3>
      <Flex justifyContent="space-between" px={2}>
        <ChecklistSection />
        <Flex flexDirection="column" maxWidth="400px">
          {canApproveTransaction ? (
            <>
              {order.securityStatus === "UNFULFILLED_REQS" && (
                <Text small alert bold>
                  Warning all requirements might not be complete as status:{" "}
                  {order.securityStatus}
                </Text>
              )}
              <Button
                mb={2}
                fullWidth
                isLoading={isLoading}
                label="Approve"
                onClick={
                  order.securityStatus === "UNFULFILLED_REQS"
                    ? openConfirmModalPress
                    : approve
                }
              />
            </>
          ) : (
            <Text bold>Security status: {order.securityStatus}</Text>
          )}

          <Modal
            isOpen={openConfirmModal}
            onClose={() => setOpenConfirmModal(false)}
          >
            <Box px={2}>
              <Text alert bold>
                Are you sure you want to approve this rental? They have not
                fulfilled all the requirements. (Security status:{" "}
                {order.securityStatus})
              </Text>

              <Text alert small mb={3}>
                (If they have completed all requirements this is a bug and
                please inform the tech team)
              </Text>
            </Box>

            <Button
              mb={2}
              fullWidth
              isLoading={isLoading}
              label="Yes approve anyway"
              onClick={approve}
            />
          </Modal>
          <RejectRental redButton order={order} />
          {error && <Error>{error}</Error>}
        </Flex>
      </Flex>
    </Box>
  );
};

export default ApprovalSection;
