import React from "react";
import styled from "styled-components";
import { Flex, Text  } from "@fatlama/llama-library";
import { Page } from "../../interfaces";
import Logout from "../Authentication/Logout";
import Icon from "../Common/Icon";

import { Link, useLocation } from "react-router-dom";
// import { getBaseurl } from "../../helpers/environment-helper";

export const NAV_WIDTH = 155;

const PointerFlex = styled(Flex)`
  cursor: pointer;
` as any;

const NavItem = ({
  iconName,
  title,
  route,
}: {
  iconName: string;
  title: string;
  route: string;
}) => {
  let location = useLocation();
  const isActive = location.pathname.includes(route);

  return (
    <Link to={route}>
      <PointerFlex alignItems="center" mb={2} p={2}>
        <Icon
          mr={2}
          height={18}
          width={18}
          icon={iconName}
          color={isActive ? "marineMute" : "white"}
        />

        <Text mb={0} color={isActive ? "marineMute" : "white"}>
          {title}
        </Text>
      </PointerFlex>
    </Link>
  );
};

const UserName = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const NavSideBar = () => {
  // const currentBaseUrl = getBaseurl();
  const username = localStorage.getItem("username");
  return (
    <Flex
      bg="midNight"
      width={NAV_WIDTH}
      minWidth={NAV_WIDTH}
      flexDirection="column"
      justifyContent="space-between"
    >
      <Flex flexDirection="column" px={2}>
        <Flex
          width="72px"
          height="72px"
          borderRadius="8px"
          overflow="hidden"
          bg="white"
          my={3}
          mx={2}
          justifyContent="center"
          align="center"
        >
          <Icon height={57} width={42} icon="larry-logo" />
        </Flex>

        {/* <NavItem route={Page.overview} iconName='overview' title='Overview' /> */}

        <NavItem route={Page.rentals} iconName="rentals" title="Rentals" />

        <NavItem
          route={Page.customerRentals + "/userId"}
          iconName="profile"
          title="Customer rentals"
        />

        {/* <NavItem
          route={Page.customerRentals}
          iconName="profile"
          title="Customers"
        /> */}
      </Flex>

      <Flex flexDirection="column" p={2}>
        {/* <Flex flexDirection="column" py={2}>
          <Text
            cursor="pointer"
            color="white"
          > Currently on {currentBaseUrl} 
          </Text>
        </Flex> */}
        <Text color="white" mb={0}>
          Logged in as:
        </Text>
        <UserName color="white" bold mb={2}>
          {username}
        </UserName>
        <Logout />
      </Flex>
    </Flex>
  );
};

export default NavSideBar;
