import RentalResults from "../Rentals/Index";
import { useParams } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import { Flex, Input, Button } from "@fatlama/llama-library";
import formikFieldWrapper from "../Common/formikFieldWrapper";
import { Field, Formik } from "formik";
import { useHistory } from "react-router-dom";
import { Page } from "../../interfaces";

const FullScreenCentered = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const FormInput = formikFieldWrapper(Input);

const CustomerRentals = () => {
  let { id } = useParams() as any;
  const history = useHistory();

  const onSubmit = async (values: any, formik: any) => {
    history.push(`${Page.customerRentals}/${values.userId}`);
  };

  if (!id || isNaN(Number(id))) {
    return (
      <FullScreenCentered>
        <Flex
          alignItems="center"
          maxWidth="350px"
          flexDirection="column"
          pt="56px"
          pb="150px"
        >
          <Formik
            onSubmit={onSubmit}
            initialValues={{
              username: "",
              password: "",
            }}
            // validate={validate}
            render={(formikBag) => {
              const {
                // setFieldTouched,
                isSubmitting,
                handleSubmit,
                // setFieldValue,
                isValid,
                // values,
              } = formikBag;
              return (
                <Flex px={3} py={2} flexDirection="column">
                  <Field
                    mb={3}
                    name="userId"
                    type="number"
                    required={true}
                    label="UserId"
                    component={FormInput}
                  />

                  <Button
                    // variant='secondary'
                    onClick={handleSubmit}
                    large
                    fullWidth
                    label="Submit"
                    isLoading={isSubmitting}
                    disabled={!isValid}
                  />
                </Flex>
              );
            }}
          />
        </Flex>
      </FullScreenCentered>
    );
  }
  return <RentalResults customerId={id ? Number(id) : undefined} />;
};

export default CustomerRentals;
