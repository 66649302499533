import React  from "react";
// import styled from "styled-components";
// import { Flex } from "@fatlama/llama-library";
import LoginForm from "./LoginForm";

// const FullScreenCentered = styled(Flex)`
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   height: 100vh;
//   width: 100vw;
// `;

// const Authenticator = () => {
//   const [hasAuthenticated, setHasAuthenticated] = useState(false);
//   const hasLoggedOut = useSelector(getHasLoggedOut);
//   const api = GetApi();

//   const authToken = localStorage.getItem("token");

//   console.log(authToken, "-authToken");
//   useEffect(() => {
//     const authenticate = async () => {
//       await api.auth();
//       setHasAuthenticated(true);
//     };
//     if (!hasLoggedOut && authToken) {
//       authenticate();
//     }
//   }, [api, hasLoggedOut, authToken]);

//   if (authToken && !hasAuthenticated) {
//     return (
//       <FullScreenCentered>
//         <SpinnerIcon />
//       </FullScreenCentered>
//     );
//   }

//   return <LoginForm />;
// };

const AuthSwitch = ({ children }: any) => {
  // const user = useSelector(getUser);
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    return <LoginForm />;
  }
  return children;
};

export default AuthSwitch;
