import React from "react";
import { VerificationItem } from "../../interfaces";
import { Box, Text, Hr } from "@fatlama/llama-library";
import { ReqStatus } from "./Components";
import RequestExtraDocs from "./RequestExtraDocs";
import AddSocialMediaLink from "./AddSocialMediaLink";
import dayjs from "dayjs";

const DocumentSummaryPanel = ({
  userVerifications,
  userId,
  orderId,
}: {
  userVerifications: VerificationItem[];
  userId: number;
  orderId: number;
}) => {
  return (
    <Box>
      {userVerifications.map((item) => {
        console.log(item, "item");
        return (
          <Box mb={3}>
            <Box mb={3}>
              <Text mb={0} small bold>
                {item.type}
              </Text>
              <ReqStatus
                status={item.status}
                docStatus={item.document?.status}
              />
              <Text mb={0} small>
                Added by: {item.addedBy}
              </Text>
              <Text mb={0} small>
                Added at:{" "}
                {dayjs(item.createdAt).utc().format("D MMM YYYY, HH:mm")}
              </Text>
            </Box>
            <Hr mb={2} />
          </Box>
        );
      })}

      <RequestExtraDocs userVerifications={userVerifications} />

      <AddSocialMediaLink
        userId={userId}
        orderId={orderId}
        alreadyHasRequirement={Boolean(
          userVerifications.find((r) => r.type === "HAS_ONLINE_PRESENCE_LINK")
        )}
      />
    </Box>
  );
};

export default DocumentSummaryPanel;
