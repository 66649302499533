import React from "react";
import styled from "styled-components";
import {
  Flex,
  H3,
  Text,
  Box,
  getColor,
  getSpace,
  Span,
} from "@fatlama/llama-library";
import {
  DuplicatedUserType,
  OrderDataType,
  ReferralHistory,
} from "../../interfaces/index";
import StatusPill from "../Common/StatusPill";
import { path } from "ramda";
import formatWithCurrency from "../../helpers/formatWithCurrency";
import dayjs from "dayjs";
import { NAV_WIDTH } from "../NavSideBar/Index";
import { getTimeSince } from "../Rentals/utils";
import utc from "dayjs/plugin/utc";
import ClickToCopy from "../Common/ClickToCopy";
import { getAdminUrl } from "../../helpers/environment-helper";

dayjs.extend(utc);

interface Props {
  idOrOfferUuid: string;
  order: OrderDataType;
  activeTab: Tab;
  setActiveTab(tab: Tab): void;
  fraudScore: number;
  borrower?: any;
  lender?: any;
  totalValueOfItemsRequested: number;
  selfReferrer?: DuplicatedUserType & {
    referrerUsedCouponIds: number[];
    referrerId: ReferralHistory["giverUserId"];
  };
}

export enum Tab {
  TRANSACTION = "TRANSACTION",
  COMMS = "COMMS",
}

// const ItemNameText = styled(Text)`
//   text-overflow: ellipsis;

//   /* Needed to make it work */
//   overflow: hidden;
//   white-space: nowrap;
// `;

const ClickableText = styled(Text)`
  cursor: pointer;
`;

const OpenLinkText = styled(ClickableText)`
  color: ${getColor("whale")};
  margin-bottom: 0;
`;
const TabContainer = styled(Flex)`
  border-bottom: 1px solid
    ${(props: any) =>
      props.isActive ? getColor("marineMute")(props) : "transparent"};
  align-items: center;
  margin-bottom: -1px;
  justify-content: center;

  cursor: pointer;
  margin-right: ${getSpace(3)};
  padding-right: ${getSpace(2)};
  padding-bottom: ${getSpace(1)};
  padding-bottom: ${getSpace(0)};

  p {
    white-space: nowrap;
  }
`;

const HeaderContainer = styled(Box).attrs({
  id: "header",
})`
  &.header-up {
    transform: translate3d(0, -100%, 0);
    transition-duration: 0.35s;
  }
  width: calc(100% - ${NAV_WIDTH}px);

  z-index: 2;
  position: fixed;

  transition: transform 0.5s ease-in-out;
  transform: translateZ(0);
  pointer-events: all;
`;

const TabButton = ({
  title,
  onClick,
  isActive,
}: {
  title: string;
  isActive: boolean;
  onClick(t?: any): any;
}) => {
  return (
    <TabContainer onClick={onClick} isActive={isActive}>
      <Text small color={isActive ? "marineMute" : "copyOne"} mb={0}>
        {title}
      </Text>
    </TabContainer>
  );
};

const RuckIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const RuckifyMarker = ({ ruckifyUserId }: any) => {
  if (!ruckifyUserId) {
    return null;
  }
  return <RuckIcon src="/images/ruckify-icon.png" />;
};

const HeaderSection = ({
  fraudScore,
  order,
  lender,
  borrower,
  idOrOfferUuid,
  activeTab,
  setActiveTab,
  selfReferrer,
  totalValueOfItemsRequested,
}: Props) => {
  // const history = useHistory();

  const goToUser = (id: number) => {
    // history.push(`/customers/${id}`);
    const adminUrl = getAdminUrl(`/User/${id}`);
    window.open(
      adminUrl?.toString(),
      "_blank" // <- This is what makes it open in a new window.
    );
  };

  const movedToSecurityReviewAt = getTimeSince(
    order.timeCustomerWaitingForReview || order.orderStatusUpdatedAt
  );

  return (
    <HeaderContainer mb={3} pt={2} bg="white">
      {/* <Flex bg="pomMute">
        <H3>PAUSED</H3>
      </Flex> */}
      <Flex py={2} mb={1} px={3}>
        <Flex
          width={1 / 3}
          borderRight="thinLight"
          pr={4}
          flexDirection="column"
        >
          <Flex alignItems="center" mb={2}>
            <H3 mb={0} mr={3}>
              Rental - {idOrOfferUuid}
            </H3>
            <Box>
              <Flex alignItems="center">
                <StatusPill
                  mb={1}
                  small
                  securityStatus={order.securityStatus}
                />

                {order.securityStatus === "IN_MANUAL_REVIEW" && (
                  <Text mb={0} ml={2} small color="pomNeon">
                    (for {movedToSecurityReviewAt})
                  </Text>
                )}
              </Flex>
              <StatusPill small mb={1} paymentStatus={order.paymentStatus} />
            </Box>
          </Flex>

          <Flex flexDirection="column">
            <a href="#customerDetails">
              <Text small color="whale" mb={0}>
                {order.items.length} item
                {order.items.length > 1 ? "s" : ""} -{" "}
                <Span small>({order.orderId})</Span>
              </Text>
            </a>
            <Text small mb={0}>
              {formatWithCurrency(order.orderFullTotal, order.currency)}
            </Text>
            <Text small mb={0}>
              {order.fromDate && dayjs(order.fromDate).utc().format("D MMM")} -{" "}
              {order.toDate && dayjs(order.toDate).utc().format("D MMM")}
            </Text>
          </Flex>
        </Flex>

        <Flex
          width={1 / 3}
          flexDirection="column"
          justifyContnt="center"
          borderRight="thinLight"
          px={4}
        >
          <Box mb={2}>
            <Flex alignitems="center">
              <Text small bold mb={0}>
                Renter - {order.customerName}
              </Text>
              <RuckifyMarker
                ruckifyUserId={path(["user", "ruckifyUserId"], borrower)}
              />
            </Flex>

            <Flex>
              <ClickToCopy text={order.customerId}>
                <Text b={0} small>
                  {order.customerId}{" "}
                </Text>
              </ClickToCopy>

              <ClickToCopy text={order.customerEmail}>
                <Text b={0} small>
                  - {order.customerEmail}{" "}
                </Text>
              </ClickToCopy>

              <OpenLinkText small onClick={() => goToUser(order.customerId)}>
                {" - "}
                open
              </OpenLinkText>
            </Flex>
          </Box>

          <Box>
            <Flex alignitems="center">
              <Text small bold mb={0}>
                Owner - {order.ownerName} -{" "}
              </Text>
              <RuckifyMarker
                ruckifyUserId={path(["user", "ruckifyUserId"], lender)}
              />
            </Flex>

            <Flex>
              <ClickToCopy text={order.ownerId}>
                <Text mb={0} small>
                  {order.ownerId}{" "}
                </Text>
              </ClickToCopy>

              <ClickToCopy text={order.ownerEmail}>
                <Text mb={0} small>
                  - {order.ownerEmail}{" "}
                </Text>
              </ClickToCopy>

              <OpenLinkText small onClick={() => goToUser(order.ownerId)}>
                {" - "}
                open
              </OpenLinkText>
            </Flex>
            {/* {order.lenderNotActivated ? (
              <Text color="pomMute">(Account not activated)</Text>
            ) : null} */}
          </Box>
        </Flex>

        <Flex width={1 / 3} flexDirection="column" pl={4}>
          <Text small>
            Item(s) value:{" "}
            {formatWithCurrency(order.totalEstimatedValue, order.currency)}
          </Text>

          <Text small>
            Total requested value:{" "}
            <Span bold small color="pomMute">
              {formatWithCurrency(totalValueOfItemsRequested, order.currency)}
            </Span>
          </Text>

          <a href={`https://admin.hygglo.se/Order/${idOrOfferUuid}`}>
            <Text link small>
              Open in Hygglo admin
            </Text>
          </a>

          {selfReferrer && (
            <ClickableText small onClick={() => goToUser(selfReferrer.id)}>
              Self referred (email):{" "}
              <Span bold small color="pomMute">
                {selfReferrer.email}
              </Span>
            </ClickableText>
          )}
        </Flex>
      </Flex>

      <Flex borderBottom="thinLight">
        <Flex width="auto" px={3}>
          <TabButton
            isActive={activeTab === Tab.TRANSACTION}
            title="Transaction"
            onClick={() => setActiveTab(Tab.TRANSACTION)}
          />
          <TabButton
            isActive={activeTab === Tab.COMMS}
            title="Communication"
            onClick={() => setActiveTab(Tab.COMMS)}
          />
        </Flex>
      </Flex>
    </HeaderContainer>
  );
};

export default HeaderSection;
