import React from "react";
import { H3  } from "@fatlama/llama-library";


import { DataRow, SectionCollapsableBlock } from "./Components";
// import styled from "styled-components";
import { OrderVerification } from "../../interfaces";

// const TwoGrid = styled(Box)`
//   display: grid;
//   grid-column-gap: ${getSpace(3)};
//   grid-template-columns: 1fr 1fr;
// `;

const OrderVerificationComponent = ({
  orderVerification,
}: {
  orderVerification?: OrderVerification;
}) => {
  if (!orderVerification) {
    return null;
  }
  const inputParsed = JSON.parse(orderVerification.verificationAlgorithmInput);
  const outputParsed = JSON.parse(
    orderVerification.verificationAlgorithmOutput
  );
  return (
    <SectionCollapsableBlock
      Title={<H3>Auto approval input/output</H3>}
      initiallyClosed
    >
      <H3>INPUT</H3>
      {Object.keys(inputParsed).map((key) => {
        return (
          <DataRow
            reverseSize
            title={key}
            value={
              typeof inputParsed[key] === "object"
                ? `(${inputParsed[key].length})`
                : inputParsed[key].toString()
            }
          />
        );
      })}

      <H3 mt={3}>RESULTS</H3>
      {Object.keys(outputParsed).map((key) => {
        return (
          <DataRow
            reverseSize
            title={key}
            alert={
              typeof outputParsed[key] === "boolean" && outputParsed[key]
                ? true
                : false
            }
            value={
              typeof outputParsed[key] === "object"
                ? outputParsed[key].length
                : outputParsed[key].toString()
            }
          />
        );
      })}
    </SectionCollapsableBlock>
  );
};

export default OrderVerificationComponent;
